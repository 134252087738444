import React from "react";
import AgileH from "../../assets/images/agile.svg"
import AgileV from "../../assets/images/agile_vertical.svg"

const Agile = () => {

  return (
    <section className="text-block agile">
      <div className="container-fluid">
        <div className="row columns-8">
          <h2 className="text-block__title">Agile process</h2>
          <div className="text-block__description">Build software with us through a transparent and low-risk process
            that you can easily control and keep an eye on. We implement Agile Scrum as part of best software
            craftsmanship practices on all project phases.
          </div>
          <div className="text-block__info">
            <div className="text-block-info">
              <div className="text-block-info__item">Analysis</div>
              <div className="text-block-info__item">Design</div>
              <div className="text-block-info__item">Development</div>
              <div className="text-block-info__item">Testing</div>
              <div className="text-block-info__item">Deployment</div>
            </div>
            <img loading="eager" className="agile__stages" src={AgileH} alt="Agile process"/>
              <img loading="eager" style={{display: 'none'}} className="agile__stages agile__stages--mobile" src={AgileV}
                   alt="Agile process"/>
          </div>

          <div className="text-block__first-value">
            <div className="process">
              <h3 className="process__title">Risk reduction</h3>
              <div className="process__description">Frequent releases of functioning software allows us to get feedback.
                Thanks to it we validate if the project is on track with your vision and requirements of key
                stakeholders. By this approach we mitigate risks of misaligned expectations and inefficient use of
                budget
              </div>
            </div>
          </div>
          <div className="text-block__second-value">
            <div className="process">
              <h3 className="process__title">Transparency</h3>
              <div className="process__description">We want our clients to understand the process of how their digital
                products are crafted. Hence we engage them in the process by direct communication with the project team
                and offer predictability through metrics and reporting to keep key stakeholders informed.
              </div>
            </div>
          </div>
          <div className="text-block__third-value">
            <div className="process">
              <h3 className="process__title">Flexibility</h3>
              <div className="process__description">Have a power to pivot or change priorities for your project as a
                reaction to user needs and market requirements. We take change in every software project as a natural
                part of the process and embrace it through Agile best practices.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Agile
