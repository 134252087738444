import React from "react";
import {Link} from "gatsby";
import Derave from "../assets/images/logo-white.svg";
import Close from "../assets/images/close_24px.svg";

class MobileMenu extends React.Component {

  render() {

    return (
      <div className="js-mobile-menu">
        <div className="mobile-menu">

          <div className="mobile-menu__top">
            <a href="/" className="mobile-menu__logo">
              <img loading="eager" src={Derave} alt="Derave Software Logo"/>
            </a>
            <div className="mobile-menu__close js-close">
              <img loading="eager" src={Close} alt="Close menu"/>
            </div>
          </div>
          <div className="mobile-menu__center">
            <nav className="mobile-menu__list">
              <ul>
                <li className={`mobile-menu__item ${this.props.activeElement === 'WhatWeDo' ? 'is-current' : ''}`}>
                  <span title="What we do" onClick={() => this.props.goTo('WhatWeDo')}>What we do</span>
                </li>
                <li className={`mobile-menu__item ${this.props.activeElement === 'HowWeWork' ? 'is-current' : ''}`}>
                  <span title="How we work" onClick={() => this.props.goTo('HowWeWork')}>How we work</span>
                </li>
                <li className={`mobile-menu__item ${this.props.activeElement === 'WhoWeAre' ? 'is-current' : ''}`}>
                  <span title="Who we are" onClick={() => this.props.goTo('WhoWeAre')}>Who we are</span>
                </li>
                <li className={`mobile-menu__item`}>
                  <Link activeClassName="is-current" title="Blog" to="/blog">Blog</Link>
                </li>
                <li className={`mobile-menu__item`}>
                  <Link activeClassName="is-current" title="Careers" to="/careers">Careers</Link>
                </li>
              </ul>
            </nav>
            <div className="mobile-menu__line"></div>
            {/*<div className="mobile-menu__lang">
            <div className="mobile-lang">
              <div className="mobile-lang__item">
                <a href="#">English</a>
              </div>
              <div className="mobile-lang__item">
                <a href="#">Polish</a>
              </div>
            </div>
          </div>*/}
            <div className="mobile-menu__button">
              <a href="#contact" className="btn-primary">Get in touch</a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MobileMenu
