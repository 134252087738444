import React from "react";
import Poster from "../../assets/images/derave.jpg"
import VideoWebm from "../../assets/images/derave-video.webm"
import VideoMP4 from "../../assets/images/derave-video.mp4"

const HowWeWork = () => {

  return (
    <section id="how-we-work" className="how scrollspy">
      <div className="how__video">
        <div className="how__video-wrapper">
          <video className="lazy" preload="none" autoPlay playsInline poster={Poster} muted
                 title="How we work" loop>
            <source data-src={VideoWebm} type="video/webm"/>
            <source data-src={VideoMP4} type="video/mp4"/>
          </video>
        </div>

        <div className="container-fluid">
          <div className="row columns-8">
            <div className="how__text">
              <div className="how__title">How we work</div>
              <div className="how__description">
                We believe that working software is a side effect of great communication and understanding of business
                needs. Focusing on the analysis lets us discover and reduce the risks in your project to put it on the
                right path to success.
                <br/>
                <b>On time. On budget.</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HowWeWork
