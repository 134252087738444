import React from "react"
import {Link} from "gatsby";
import logo from "../assets/images/logo.svg";
import hamburger from "../assets/images/menu_24px.svg";

function Header(props) {

  return (
    <div className="header">
      <div className="container-fluid">
        <div className="row columns-8">
          <div className="top-logo">
            <a title="Homepage" href="/">
              <img loading="eager" src={logo} alt="Derave Software Logo"/>
            </a>
          </div>
          <ul className="top-menu row columns-5">
            <li className={`top-menu__item ${props.activeElement === 'WhatWeDo' ? 'is-current' : ''}`}>
              <span title="What we do" onClick={() => props.goTo('WhatWeDo')}>What we do</span>
            </li>
            <li className={`top-menu__item ${props.activeElement === 'HowWeWork' ? 'is-current' : ''}`}>
              <span title="How we work" onClick={() => props.goTo('HowWeWork')}>How we work</span>
            </li>
            <li className={`top-menu__item ${props.activeElement === 'WhoWeAre' ? 'is-current' : ''}`}>
              <span title="Who we are" onClick={() => props.goTo('WhoWeAre')}>Who we are</span>
            </li>
            <li className={`top-menu__item`}>
              <Link activeClassName="is-current" title="Blog" to="/blog">Blog</Link>
            </li>
            <li className={`top-menu__item`}>
              <Link activeClassName="is-current" title="Careers" to="/careers">Careers</Link>
            </li>
          </ul>

          {/*<ul className="top-languages">*/}
          {/*  <li><a href="#">English</a></li>*/}
          {/*  <li><a className="is-disable" href="#">Polish</a></li>*/}
          {/*</ul>*/}

          <div className="top-button">
            <Link title="Get in touch" to="#contact" className="btn-primary">Get in touch</Link>
          </div>

          <div className="header__burger js-burger" role="button">
            <img loading="eager" src={hamburger} alt="Mobile menu"/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
