import React from "react";
import {Link} from "gatsby";

const Hero = () => {

  return (
    <section id="start" className="hero">
      <div className="container-fluid">
        <div className="row columns-8">
          <div className="hero__big animated">Automate</div>
          <div className="hero__line"></div>
          <div className="hero__small">We are a team of experts who can understand your business</div>

          <div className="hero__big animated">Key</div>
          <div className="hero__small">to suggest optimised processes and implement high-performance solutions</div>

          <div className="hero__big animated">Business</div>
          <div className="hero__small">for automated workflows of the future</div>

          <div className="hero__big animated">Processes</div>
          <div className="hero__button">
            <Link to="#what-we-do" className="btn-white">See our services</Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
