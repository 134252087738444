import React from "react";
import EventStorming from "../../assets/images/storming.svg"

const Storming = () => {

  return (
    <section className="text-block storming">
      <div className="container-fluid">
        <div className="row columns-8">
          <h1 className="text-block__title">Event Storming discovery</h1>
          <h3 className="text-block__description">This lightweight discovery workshop makes it possible to effectively
            communicate between IT engineers and key stakeholders on premise or on-line. Cooperatively build a model
            that highlights complexity and threats of your project through a use of simple notation.</h3>

          <div className="text-block__info">
            <img loading="eager" src={EventStorming} alt="Event storming"/>
          </div>

          <div className="text-block__first-value">
            <div className="process">
              <h4 className="process__title">Versatile outcome</h4>
              <div className="process__description">Create a model that will make sure your managers will have their
                vision described. UX/UI designers will see all information needed to reflect that vision and the
                development team will discover all the business logic that needs to be implemented. All of that while
                working on the same model.
              </div>
            </div>
          </div>
          <div className="text-block__second-value">
            <div className="process">
              <h4 className="process__title">Predictability</h4>
              <div className="process__description">Get ahead of problems and solve the unknowns before diving into the
                development process. Reduce the amount of wrong decisions which get implemented and cost your project
                valuable time and budget.
              </div>
            </div>
          </div>
          <div className="text-block__third-value">
            <div className="process">
              <h4 className="process__title">Simplicity</h4>
              <div className="process__description">Rather than using complex modeling techniques like UML, Event
                Storming breaks the process down into simple notation that everyone can understand. Thanks to this
                non-technical stakeholders can participate in valuable discussions on the same level with software
                engineers.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Storming
