import React from "react";
import {Link} from "gatsby";

import CallMissedOutgoing from "../../assets/images/call_missed_outgoing_24px.svg"
import Devices from "../../assets/images/devices_24px.svg"
import Mode from "../../assets/images/mode_24px.svg"
import ShoppingCart from "../../assets/images/shopping_cart_24px.svg"
import DoneAll from "../../assets/images/done_all_24px.svg"
import SupervisorAccount from "../../assets/images/supervisor_account_24px.svg"


const WhatWeDo = () => {

  return (
    <section id="what-we-do" className="what-we-do scrollspy">
      <div className="container-fluid">
        <div className="row columns-8">
          <div className="what-we-do__text">
            <div className="what-we-do__title">What we do</div>
            <div className="what-we-do__description">
              We specialize in delivery of scalable tailored solutions. Thanks to our experience we provide an ecosystem
              of complementary services.
            </div>
          </div>
          <div className="what-we-do__bg"></div>

          <ul className="what-we-do-list">
            <li className="what-we-do-list__item row columns-16">
              <div className="what-we-do-list__icon">
                <img loading="eager" src={CallMissedOutgoing} alt="Business process modeling icon"/>
              </div>
              <h2 className="what-we-do-list__title">Business process modeling</h2>
              <div className="what-we-do-list__text">To create solutions efficiently, we have to understand your
                business. With our experience and using a modern analytical approach we will help with ideating key
                processes and propose solutions to them.
              </div>
            </li>

            <li className="what-we-do-list__item row columns-16">

              <div className="what-we-do-list__icon">
                <img loading="eager" src={Devices} alt="Web apps icon"/>
              </div>
              <h2 className="what-we-do-list__title">Web apps</h2>

              <div className="what-we-do-list__text">Bespoke solutions to fit your business. We specialize in custom
                built web applications and deliver high performance and UX optimised solutions for your business.
              </div>
            </li>

            <li className="what-we-do-list__item row columns-16">

              <div className="what-we-do-list__icon">
                <img loading="eager" src={Mode} alt="Customer Relationship Management (CRM) icon"/>
              </div>
              <h2 className="what-we-do-list__title">Customer Relationship Management (CRM)</h2>

              <div className="what-we-do-list__text">Each client is different. Focus on building relationships. Automation
                will do the rest.
              </div>
            </li>

            <li className="what-we-do-list__item row columns-16">

              <div className="what-we-do-list__icon">
                <img loading="eager" src={ShoppingCart} alt="E-commerce solutions icon"/>
              </div>
              <h2 className="what-we-do-list__title">E-commerce solutions</h2>

              <div className="what-we-do-list__text">We’ll help you take your current e-commerce solution to a more mature
                level. Save time on managing product catalogue, reduce the manual work when invoicing and automate your
                logistics. Retain your customers with marketing automation and loyalty programs.
              </div>
            </li>

            <li className="what-we-do-list__item row columns-16">

              <div className="what-we-do-list__icon">
                <img loading="eager" src={DoneAll} alt="System integrations icon"/>
              </div>
              <h2 className="what-we-do-list__title">System integrations</h2>

              <div className="what-we-do-list__text">With the growth of your business you notice that the same data
                circulates between your ERP, accounting, suppliers and other systems used in your daily operations. This
                circulation very often requires manual maintaining and degrades data quality. Automize all that work and
                create one solid ecosystem which will become your decision hub.
              </div>
            </li>

            <li className="what-we-do-list__item row columns-16">
              <div className="what-we-do-list__icon">
                <img loading="eager" src={SupervisorAccount} alt="Workshops icon"/>
              </div>
              <h2 className="what-we-do-list__title">Business IT Analysis</h2>
              <div className="what-we-do-list__text">Engage with us through a series of workshops that will help you map and
                plan your future digital product. Focus on the critical business goals and know all the risks before the
                implementation phase - deliver a great starting point for your UX and Development team.
              </div>
            </li>
          </ul>

          <div className="what-we-do__button">
            <Link to="/#contact" className="btn-outline">Work with us</Link>
          </div>
          <div className="what-we-do__bg what-we-do__bg--mb"></div>
        </div>
      </div>
    </section>
  )
}

export default WhatWeDo
