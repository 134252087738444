import React from "react";
import {graphql, Link} from "gatsby";
import PostsLoop from "../blog/posts-loop";

const WhatWeWrite = ({posts}) => {

  return (
    <section className="article-index">
      <div className="container-fluid">
        <div className="article-index__title">What we write</div>

        <div className="row article-index__row">
          <PostsLoop posts={posts} />
        </div>

        <div className="row columns-8">
          <div className="article-index__button">
            <Link to="/blog" className="btn-outline">SEE MORE</Link>
          </div>
        </div>
      </div>
    </section>

  )
}

export default WhatWeWrite

export const pageQuery = graphql`
  query WordPressPostArchive3($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
      }
    }
  }
`
