import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/homepage/hero";
import WhatWeDo from "../components/homepage/what-we-do";
import HowWeWork from "../components/homepage/how-we-work";
import Agile from "../components/homepage/agile";
import Storming from "../components/homepage/storming";
//import WorkedWith from "../components/homepage/worked-with";
//import Testimonials from "../components/homepage/testimonials";
import WhoWeAre from "../components/homepage/who-we-are";
import Header from "../components/header";
import ScrollNavigation from 'react-single-page-navigation';
import Contact from "../components/contact";
import MobileMenu from "../components/mobile-menu";
import WhatWeWrite from "../components/homepage/what-we-write";
import {graphql} from "gatsby";
import Newsletter from "../components/newsletter";
import logo from "../assets/images/logo.svg";
import useSiteMetadata from "../components/site-data"

const Home = ({
                data,
                pageContext: {nextPagePath, previousPagePath},
              }) => {


  const {siteUrl} = useSiteMetadata();
  const schema = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "Derave Software",
    "image": siteUrl + logo,
    "url": "https://deravesoftware.com/",
    "telephone": "+48690253147",
    "priceRange": "$$$",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Fryderyka Chopina 41/2",
      "addressLocality": "Lublin",
      "postalCode": "20-023",
      "addressCountry": "PL"
    } ,
    "sameAs": [
      "https://pl.linkedin.com/company/derave-software",
      "https://www.facebook.com/derave.software"
    ]
  };

  const posts = data.allWpPost.nodes

  return (
    <Layout isHomePage>
      <SEO
        title="Derave Software - Event-based approach to software development"
        schema={schema}/>

      <ScrollNavigation offset={-58} elements={{
        Hero: {},
        WhatWeDo: {},
        HowWeWork: {},
        WhoWeAre: {},
        Contact: {}
      }}>
        {({refs, activeElement, goTo}) => (
          <div>
            <Header goTo={goTo} activeElement={activeElement}/>
            <div ref={refs.Hero}>
              <Hero/>
            </div>
            <div ref={refs.WhatWeDo}>
              <WhatWeDo/>
            </div>
            <div ref={refs.HowWeWork}>
              <HowWeWork/>
              <Agile/>
              <Storming/>
            </div>
              <WhatWeWrite posts={posts} />
              <Newsletter/>
            <div ref={refs.WhoWeAre}>
              <WhoWeAre/>
            </div>
            <div ref={refs.Contact}>
              <Contact/>
            </div>
            <MobileMenu goTo={goTo} activeElement={activeElement}/>
          </div>
        )}
      </ScrollNavigation>

      {/*<WorkedWith/>*/}
      {/*<Testimonials/>*/}

    </Layout>
  )
}


export default Home

export const pageQuery = graphql`
  query WordPressPostArchive2($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        slug
        
        post_acfs {
          ctaLink
          ctaText
        }
        
        author {
          node {
            firstName
            lastName
            slug
          }
        }
        
        terms {
          nodes {
            name
            slug
          }
        }
        
        featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 804, quality: 95) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
        
      }
    }
  }
`
