import React from "react";
import Krystianx3 from "../../assets/images/krystian-biela.jpg"
import Krystianx2 from "../../assets/images/krystian-biela-x2.jpg"
import Krystianx1 from "../../assets/images/krystian-biela-x1.jpg"

import Patrykx3 from "../../assets/images/patryk-karczmarczyk.jpg"
import Patrykx2 from "../../assets/images/patryk-karczmarczyk-x2.jpg"
import Patrykx1 from "../../assets/images/patryk-karczmarczyk-x1.jpg"

const WhoWeAre = () => {

  return (
    <section id="who-we-are" className="who-we-are scrollspy">
      <div className="container-fluid">
        <div className="row columns-8">
          <div className="who-we-are__title">Who we are</div>
          <div className="who-we-are__description">Young, fast-growing software house built on a solid foundation of experience. We have a great team of twelve on the board and we don’t stop expanding.</div>
          <div className="we-are">

            <div className="we-are__item">
              <div className="we-are__image">
                <img loading="eager" src={Krystianx1} srcSet={
                  `${Krystianx2} 2x, ${Krystianx3} 3x`
                } alt="Krystian Biela"/>
              </div>
              <h5 className="we-are__title">Krystian Biela</h5>
              <div className="we-are__description">Co-founder</div>
            </div>


            <div className="we-are__item">
              <div className="we-are__image">
                <img loading="eager" src={Patrykx1} srcSet={
                  `${Patrykx2} 2x, ${Patrykx3} 3x`
                } alt="Patryk Karczmarczyk"/>
              </div>
              <h5 className="we-are__title">Patryk Karczmarczyk</h5>
              <div className="we-are__description">Co-founder</div>
            </div>


          </div>
        </div>
      </div>
    </section>
  )
}

export default WhoWeAre
